<template>
  <div>
    <!-- <el-card class="tips" v-if="a">
      <i></i>
      <span
        >Your subscription will expire in 25 days. Please pay in advance in
        order to avoid the normal use of website functions</span
      >
    </el-card>-->

    <!-- Visits -->
    <!-- <el-card class="box-card" style="margin-top: -10px">
      <el-row>
        <el-col :span="6">
          <p class="p1">20</p>
          <p>{{ $t("index.visits") }}</p>
        </el-col>
        <el-col :span="6">
          <p class="p2">20</p>
          <p>{{ $t("index.collections") }}</p>
        </el-col>
        <el-col :span="6">
          <p class="p3">20</p>
          <p>{{ $t("index.quantity") }}</p>
        </el-col>
        <el-col :span="6">
          <p class="p4">4.0</p>
          <p>{{ $t("index.storeScore") }}</p>
        </el-col>
      </el-row>
    </el-card>-->
    <!-- Orders Trackers 订单追踪器 -->
    <el-row class="box-card-1">
      <h1>{{ $t("index.ordersTrackers") }}</h1>
      <el-col :span="6">
        <div class="border-1 mt">
          <p class="h1">{{ this.orderTracker.new }}</p>
          <p class="h2">{{ $t("index.new") }}</p>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="border-2 mt">
          <p class="h1">{{ this.orderTracker.progress }}</p>
          <p class="h2">{{ $t("index.progress") }}</p>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="border-3 mt">
          <p class="h1">{{ this.orderTracker.shipped }}</p>
          <p class="h2">{{ $t("index.shipped") }}</p>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="border-4 mt">
          <p class="h1">{{ this.orderTracker.failed }}</p>
          <p class="h2">{{ $t("index.failed") }}</p>
        </div>
      </el-col>
    </el-row>
    <!-- Chart 图表 -->
    <el-row :gutter="12" class="mt1">
      <!-- Sales proportion 销售比例 -->
      <el-col :span="12">
        <el-card class="proportion">
          <div ref="analysis" style="height: 306px"></div>
          <p>{{ $t("index.monthly") }}</p>
        </el-card>
      </el-col>
      <!-- June report 六月报告 -->
      <el-col :span="12">
        <el-card class="report">
          <p class="span1 fl">{{ thisYear }}{{ $t("index.report") }}</p>
          <p class="span2 fl"></p>
          <!-- <p class="span3 fr">{{ $t("index.all") }}</p> -->
          <div class="clear"></div>
          <div ref="report" style="height: 360px"></div>
        </el-card>
      </el-col>
    </el-row>

    <!-- 弹出认证 -->
    <!-- <el-dialog
      :visible="myVisible"
      width="35%"
      @close="close"
      class="authentication"
    >
      <img src="@/assets/authentication.png" />
      <div>
        <p class="mt">You haven't been authenticated yet. Please go to the</p>
        <p>authentication office immediately</p>
      </div>
      <el-button class="button1" @click="resident">Certified now</el-button>
    </el-dialog>-->

    <!-- 弹出认证 -->

    <!-- 弹出认证 -->
    <!-- <el-dialog
      v-if="storeStatus == 0"
      :visible="myVisible"
      width="35%"
      :show-close="false"
      class="authentication"
    >
      <img class="img1" src="@/assets/examine.png" />
      <div>
        <p class="mt">
          {{ $t("index.after") }}
        </p>
      </div>
    </el-dialog>-->

    <!-- bottom -->
    <!-- <el-card class="mt1">
      <el-row>
        <el-col :span="2">
    <img :src="this.img" class="img" />-->
    <!-- <img src="@/assets/Rectangle_220.jpg" class="img" />
        </el-col>
    <el-col :span="4">-->
    <!-- <p class="productName mbp">{{ this.productName }}</p> -->
    <!-- <p class="productName mbp">
            4pairs Toddler Girls Fruit Print SocksPrint SocksPrint
            SocksocksPrint SocksPrint Socks
          </p>
        </el-col>
        <el-col :span="4">
          <p class="p1">Four pairs of yellow</p>
        </el-col>
        <el-col :span="3">
          <p class="p1">$500</p>
        </el-col>
        <el-col :span="3">
          <div class="p1">
            <p class="mbp">December 6,2021</p>
            <p class="mbp">10:00:00</p>
          </div>
        </el-col>
        <el-col :span="4">
          <p class="p1 payment">Pending payment</p>
        </el-col>
        <el-col :span="4">
          <el-button class="button">View now</el-button>
        </el-col>
      </el-row>
    </el-card>-->
  </div>
</template>

<script>
import { renderData } from "@/helper/api";
const moment = require("moment");

export default {
  data() {
    return {
      orderTracker: {},
      topProducts: [],
      myVisible: true,
      userInfo: localStorage.getItem("vendor_user"),
      saleMonth: [],
      monthSaleData: [],
      countriesSalesData: [],
      thisYear: "",
    };
  },
  created() {
    // this.$router.options.routes = [
    //   ...this.$router.options.routes,
    //   {
    //     path: "/abc/abc",
    //     name: "abc",
    //     component: () => import("@/views/account/personal.vue"),
    //     meta: {
    //       layout: "default-layout"
    //     }
    //   }
    // ];
    // console.log(
    //   this.$router.addRoutes([
    //     {
    //       path: "/abc/abc",
    //       name: "abc",
    //       component: () => import("@/views/account/personal.vue"),
    //       meta: {
    //         layout: "default-layout"
    //       }
    //     }
    //   ])
    // );
    // this.category();
    // const old = this.$router.options.routes;
  },
  mounted() {
    // this.category();
    // this.brokenLine();
    this.judge();
    this.getList();
    // if (location.href.indexOf("#reloaded") <= 0) {
    //   location.href = location.href + "#reloaded" + "#reloaded";
    //   location.reload();
    // }

    this.getSaleMonth();
    this.getSaleCountry();

    this.thisYear = moment(new Date()).format("YYYY");
  },

  methods: {
    // beforeRouteLeave(to, from, next){
    //   if(to.name ==='resident' ){
    //     next({name: 'resident'});
    //   }else {
    //     next();
    //   }
    // },
    // 获取商店状态
    judge() {
      const userInfo = localStorage.getItem("vendor_user");
      if (userInfo && userInfo.store === null) {
        this.$router.replace("/resident");
      }
    },

    // 关闭弹窗
    // close() {
    //   this.myVisible = false;
    // },

    // 跳转到订阅页面
    // payment() {
    //   this.$router.push("/subscription/add_subscription");
    // },

    // 获取数据
    getList() {
      renderData("GET", "dashboard/all").then((res) => {
        this.orderTracker = res.data.orderTracker;
      });
      renderData("GET", "store").then((res) => {
        localStorage.setItem("storeId", res.data._id);
      });
    },
    getSaleCountry() {
      renderData("GET", "orderdata/sale-country").then((res) => {
        let countries = [];
        let saleData = [];
        let bgColor = ["#F6BD16", "#9DB3FF", "#5B8FF9"];
        this.countriesSalesData = res.data;
        res.data.forEach((item, key) => {
          countries.push(item.country);
          saleData.push({
            value: item.salePrices,
            itemStyle: {
              color: bgColor[key % 3],
            },
          });
        });

        this.category(countries, saleData);
      });
    },
    getSaleMonth() {
      renderData("GET", "orderdata/sale-month").then((res) => {
        let data = res.data.monthSaleData;
        let monthSaleData = [];
        let saleMonth = [];

        data.forEach((item, key) => {
          saleMonth.push(key + 1);
          monthSaleData.push(item);
        });

        this.brokenLine(saleMonth, monthSaleData);
        this.category();
      });
    },

    // 左边柱状图
    category(countries, saleData) {
      const myChart = this.$echarts.init(this.$refs["analysis"]);
      let option = {
        title: {
          text: this.$t("index.proportion"),
        },
        yAxis: {
          type: "category",
          data: countries,
          axisTick: {
            show: false,
          },
        },
        xAxis: {
          type: "value",
          scale: true,
          max: 20000,
          min: 0,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        series: [
          {
            data: saleData,
            type: "bar",
            barWidth: 20, //柱图宽度
          },
        ],
        grid: {
          left: "3%",
          top: "10%",
          bottom: "2%",
          height: "85%",
          containLabel: true,
        },
      };
      myChart.setOption(option);
    },
    // 右边折线图
    brokenLine(months, saleData) {
      const myChart = this.$echarts.init(this.$refs["report"]);
      let option = {
        grid: {
          left: "3%",
          right: "4%",
          top: "10%",
          bottom: "3%",
          height: "69%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: months,
            axisTick: {
              show: false,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLabel: { formatter: "${value}" },
          },
        ],
        series: [
          {
            type: "line",
            areaStyle: {},
            data: saleData,
          },
        ],
      };
      myChart.setOption(option);
    },
  },
};
</script>

<style scoped>
.tips {
}
.box-card {
  margin-top: 10px;
}
.box-card p {
  text-align: center;
  font-size: 13px;
}
.box-card .p1 {
  color: #f6bd16;
  font-size: 20px;
}
.box-card .p2 {
  color: #5b8ff9;
  font-size: 20px;
}
.box-card .p3 {
  color: #7868fd;
  font-size: 20px;
}
.box-card .p4 {
  color: #61ddaa;
  font-size: 20px;
}

.box-card h1 {
  font-size: 30px;
}
.box-card p {
  margin-top: 10px;
  font-size: 15px;
}
.border-1 {
  height: 68px;
  margin-left: 28px;
  border-left: 3px solid #ffc55f;
}
.box-card-1 {
  margin-top: 10px;
  height: 168px;
  background: #fff;
  border-radius: 10px;
}
.box-card-1 h1 {
  font-size: 18px;
  font-weight: 700;
  margin-top: 22px;
  margin-left: 27px;
}
.h1 {
  font-size: 20px;
  padding-top: 10px;
  margin-left: 15px;
}
.h2 {
  font-size: 13px;
  margin-top: 5px;
  margin-left: 15px;
  color: grey;
}
.border-2 {
  height: 68px;
  margin-left: 50px;
  border-left: 3px solid #ff8784;
}
.border-3 {
  height: 68px;
  margin-left: 50px;
  border-left: 3px solid #538fff;
}
.border-4 {
  height: 68px;
  margin-left: 50px;
  border-left: 3px solid #49dcbb;
}
.proportion {
  height: 376px;
  border-radius: 10px;
}
.proportion p {
  font-size: 12px;
  text-align: center;
  color: #858585;
}
.report {
  height: 378px;
  border-radius: 10px;
}
span {
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
}
.span1 {
  font-size: 20px;
  font-weight: 700;
  margin-top: 2px;
}
.span2 {
  margin-left: 20px;
  font-size: 16px;
  line-height: 12px;
  font-weight: 700;
  margin-top: 10px;
  color: grey;
}
.span3 {
  font-weight: 700;
  margin-right: 50px;
  margin-top: 5px;
  font-size: 17px;
}

.img {
  width: 100px;
  height: 100px;
  margin-top: -10px;
}
.payment {
  color: rgb(23, 144, 243);
}
.input {
  width: 200px;
  height: 50px;
  margin-top: 20px;
  border-radius: 10px;
  background: #ff0a0a;
}
.input span {
  color: #fff;
  margin-left: 60px;
  line-height: 50px;
}
.mbp {
  font-weight: 700;
  font-size: 13px;
}
.button {
  width: 200px;
  background: #ff0a0a;
  margin-right: 10px;
  border-radius: 10px;
  margin-top: 20px;
  font-size: 18px;
  color: #fff;
}
.p1 {
  margin-top: 30px;
  font-weight: 700;
  font-size: 13px;
}
.img {
  width: 80px;
  height: 73px;
  margin-top: 5px;
  margin-left: 10px;
}
.productName {
  margin-top: 30px;
}
.authentication img {
  margin-top: -20px;
  margin-left: 30%;
}
.authentication .img1 {
  margin-top: -20px;
  margin-left: 33%;
}
.authentication p {
  font-size: 13px;
  margin-bottom: 10px;
  text-align: center;
  margin-left: 30px;
}
.authentication .button1 {
  background: #ff0a0a;
  font-size: 15px;
  width: 218px;
  height: 52px;
  color: #fff;
  border-radius: 10px;
  margin: 40px 0 40px 35%;
}
</style>